﻿/**
*	Glitche - CV/Resume/Portfolio Template (HTML)
*	Version: 1.3
*	Author: beshleyua
*	Author URL: http://themeforest.net/user/beshleyua
*	Copyright © Cvio by beshleyua. All Rights Reserved.
**/

/* TABLE OF CONTENTS
	1. Basic
	2. Container
    3. Columns
    4. Typography
    5. Links
    6. Buttons
    7. Preloader
    8. Forms
    9. Lists
    10. Code
    11. Tables
    12. Alignment
    13. Text Formating
    14. Blockquote
    15. Animations
*/

/* 1. Basic */
html {
	margin-right: 0!important;
}

body {
    margin: 0;
	padding: 0;
	border: none;
	font-family: $default-font;
	font-size: $default-size;
	color: $default-color;
	background: $white-color;
	letter-spacing: $default-letter-spacing;
	font-weight: 400;
	header, footer, .section {
		opacity: 0;
		visibility: hidden;
	}
	&.loaded {
		header, footer, .section {
			opacity: 1;
			visibility: visible;
		}
	}
	&.scroll_hidden {
		overflow: hidden;
		height: 100vh;
	}
}

* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.clear {
	clear: both;
}

/* 2. Container */
.container-custom {
	position: relative;
	// margin: 30px;
	overflow: hidden;
	background: $bg;
	// width: 100%;
    padding-right: 0;
    padding-left: 0;
	@media (max-width: 840px) {
		margin: 15px;
	}
	.line {
		content: '';
		position: fixed;
		background: $active-color;
		z-index: 105;
		&.top {
			left: 0;
			top: 0;
			width: 100%;
			height: 5px;
			@media (max-width: 840px) {
				height: 15px;
			}
		}
		&.bottom {
			left: 0;
			top: auto;
			bottom: 0;
			width: 100%;
			height: 5px;
			@media (max-width: 840px) {
				height: 15px;
			}
		}
		&.left {
			left: 0;
			top: 0;
			width: 5px;
			height: 200%;
			@media (max-width: 840px) {
				width: 15px;
			}
		}
		&.right {
			left: auto;
			right: 0;
			top: 0;
			width: 5px;
			height: 200%;
			@media (max-width: 840px) {
				width: 15px;
			}
		}
	}
}

.wrapper {
	overflow: hidden;
	position: relative;
	margin: 0 auto;
	max-width: 1140px;
}

/* 3. Columns */
.cols {
	position: relative;
	.col {
		&.col-sm {
			float: left;
			width: 25%;
			@media (max-width: 840px) {
				width: 100%;
			}
		}
		&.col-lg {
			float: left;
			width: 75%;
			@media (max-width: 840px) {
				width: 100%;
			}
		}
		&.col-md {
			float: left;
			width: 50%;
			@media (max-width: 840px) {
				width: 100%;
			}
		}
	}
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

/* 4. Typography */
h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	font-family: $default-font;
	margin: 0;
	margin-bottom: 30px;
	color: $dark-color;
}

h1 {
	font-size: $h1-size;
}

h2 {
	font-size: $h2-size;
}

h3 {
	font-size: $h3-size;
}

h4 {
	font-size: $h4-size;
}

h5 {
	font-size: $h5-size;
}

h6 {
	font-size: $h6-size;
}

p {
	font-size: $default-size;
	line-height: $line-height;
	padding: 0;
	margin: 30px 0;
}

strong {
	font-weight: 500;
}

/* 5. Links */
a { 
	color: $default-color;
	text-decoration: none;
	&::after {
		opacity: 1;
		transform: translate3d(-100%, 0, 0);
	}

	&:hover {
		color: $selection-color;
		text-decoration: underline;
	}
	&:hover::after,
	&:focus::after {
		transform: translate3d(0, 0, 0);
	}
}

/* 6. Buttons */
a.btn, .btn {
	display: inline-block;
	vertical-align: middle;
	height: 42px;
	line-height: 40px;
	text-align: center;
	color: $default-color;
	font-size: $default-size;
	background: none;
	border: 1px solid $light-color;
	position: relative;
	overflow: hidden;
	padding: 0 30px;
	cursor: pointer;
	@include transition(all 0.3s ease 0s);
	&.fill {
		color: $bg;
		background: $active-color;
		border: 1px solid $active-color;
	}
	&:hover {
		color: $bg;
		background: $active-color;
		border: 1px solid $active-color;
	}
	outline-color: $default-color;
}

/* 7. Preloader */
.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1000;
	top:0;
	left:0;
	text-align: center;
	.load {
		color: $active-color;
		font-size: $small-size;
	}
	.typed-load, .typed-cursor {
		color: $active-color;
		font-size: $small-size;
	}
}

/* 8. Forms */
input,
textarea,
button {
	display: block;
	background: none;
	font-family: $default-font;
	font-size: $small-size;
	height: 60px;
	width: 100%;
	color: $dark-color;
 	margin-bottom: 30px;
	padding: 0;
	border: none;
	border-bottom: $form-border;
	-webkit-appearance: none;
	resize: none;
	@include transition(all 0.3s ease 0s);
	@include border-radius(0px);
	outline: 0;
	&:focus {
		color: $dark-color;
		border-bottom: $form-border-hover;
	}
}

textarea {
	height: 120px;
	padding: 0;
	margin-top: 55px;
}

button {
	width: auto;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	border-bottom: $form-border;
	cursor: pointer;
	color: $dark-color;
	&:hover {
		border-bottom: $form-border-hover;
	}
}

label, legend { 
	display: block;
	padding-bottom: 10px;
	font-family: $default-font;
	font-size: $default-size;
}

fieldset {
	border-width: 0; 
	padding: 0; 
}

input[type="checkbox"], 
input[type="radio"] { 
	display: inline; 
}

::-webkit-input-placeholder {
	color: $extra-color;
}

:-moz-placeholder { 
	color: $extra-color;
}

::-moz-placeholder {
	color: $extra-color;
}

:-ms-input-placeholder {  
	color: $extra-color;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
	color: $dark-color;
}

input:focus:-moz-placeholder, textarea:focus:-moz-placeholder { 
	color: $dark-color;
}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
	color: $dark-color;
}

input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {  
	color: $dark-color;
}

input.error, textarea.error {
	border-bottom: 1px solid $error-color!important;
}

label.error {
	display: none!important;
}

input.error::-moz-placeholder, 
textarea.error::-moz-placeholder {
	color: $error-color;
}

input.error:-moz-placeholder, 
textarea.error:-moz-placeholder {
	color: $error-color;
}

input.error:-ms-input-placeholder, 
textarea.error:-ms-input-placeholder {
	color: $error-color;
}

input.error::-webkit-input-placeholder, 
textarea.error::-webkit-input-placeholder {
	color: $error-color;
}

/* 9. Lists */
ol, ul { 
	list-style: none;
	margin-top: 0px; 
	margin-bottom: 0px;
	padding-left: 0px; 
}

ul ul, 
ul ol,
ol ol, 
ol ul { 
	margin-bottom: 0px;
}

li { 
	margin-bottom: 0px;
}

/* Code */
code {
	padding: 0 4px;
	font-style: italic;
	color: $extra-color;
	text-decoration: none;
	display: inline-block;
	vertical-align: middle;
	overflow: auto;
	max-width: 100%;

	table {
		margin: 0;
	}
}

pre {
	margin: 25px 0;
	padding: 25px;
	max-width: 100%;
	overflow: auto;
	white-space: pre;

	& > code {
		display: block;
		padding: 0;
		font-style: normal;
		line-height: 1.8em;
	}
}

mark, ins {
	background: #eee;
	text-decoration: none;
}

figure {
	margin: 25px 0;
}

/* 11. Tables */
table {
	width: 100%;
	margin: 30px 0;
	padding: 0;
	border-collapse: collapse;
}

th {
	font-weight: 500;
	border: none;
	border-bottom: 1px solid $light-color;
	color: $dark-color;
	padding: 12px 15px; 
	text-align: left;
}

td {
	border: none;
	border-bottom: 1px solid $light-color;
	padding: 12px 15px; 
	text-align: left;
	color: $default-color;
}

/* 12. Alignment */
.align-center {
	text-align: center !important;
}

.align-right {
	text-align: right !important;
}

.align-left {
	text-align: left !important;
}

.pull-right { 
	float: right !important;
}

.pull-left { 
	float: left !important; 
}

.pull-none { 
	float: none !important;
}

.full-width {
	max-width: 100% !important;
	width: 100% !important; 
}

.full-width-force {
	max-width: 100vw;
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	z-index: 20;
}

.full-max-width {
	max-width: 100% !important;
	width: auto !important;
}

.centrize {
	display: table !important;
	table-layout: fixed !important;
	height: 100% !important;
	position: relative !important;
}

.vertical-center {
	display: table-cell !important;
	vertical-align: middle !important;
}

/* 13. Text Formating */
.text-uppercase {
	text-transform: uppercase !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-regular {
	font-weight: 400 !important;
}

.text-bold {
	font-weight: 700 !important;
}

.text-italic {
	font-style: italic !important;
}

/* blockquote */
blockquote {
	margin: 25px 0;
	padding: 0 20px;
	color: #888;
	font-style: italic;
	font-weight: 300;
	border-left: 2px solid #78cc6d;
}

blockquote code {
	padding: 2px;
	display: inline;
}

blockquote *:last-child {
	margin-bottom: 0;
}

blockquote p {
	margin-bottom: 10px;
}

blockquote cite {
	font-size: $small-size;
}

/* iframes */
iframe, object {
	max-width: 100%;
	margin: 0 0 25px 0;
}

p {
	iframe {
		margin: 0;
	}
}

/* Paginations */

.pagination {
	clear: both;
	padding: 20px 0 60px;
	text-align: center;

	.page-numbers {
		margin: 0 5px;
		display: inline-block;
		vertical-align: top;

		&.current {
			color: $extra-color;
		}
	}
}

/* Animations */

/* Glitch Animations */
@media (min-width: 580px) {
	.glitch-effect, .glitch-effect-white {
		position: relative;
	}
	.glitch-effect:before, .glitch-effect:after, .glitch-effect-white:before, .glitch-effect-white:after {
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: $white-color;
		clip: rect(0, 0, 0, 0);
	}
	.glitch-effect-white:before, .glitch-effect-white:after {
		background: $active-color;
	}
	.glitch-effect:after, .glitch-effect-white:after {
		left: 2px;
		text-shadow: -1px 0 $active-color;
		animation: glitch-1 4s infinite linear alternate-reverse;
	}
	.glitch-effect:before, .glitch-effect-white:before {
		left: -2px;
		text-shadow: 2px 0 $active-color;
		animation: glitch-2 4s infinite linear alternate-reverse;
	}
}

@keyframes glitch-1 {
	0% {
		clip: rect(20px, 920px, 51px, 0);
	}
	5% {
		clip: rect(83px, 920px, 102px, 0);
	}
	10% {
		clip: rect(83px, 920px, 105px, 0);
	}
	15% {
		clip: rect(95px, 920px, 11px, 0);
	}
	20% {
		clip: rect(105px, 920px, 56px, 0);
	}
	25% {
		clip: rect(40px, 920px, 19px, 0);
	}
	30% {
		clip: rect(56px, 920px, 97px, 0);
	}
	35% {
		clip: rect(9px, 920px, 17px, 0);
	}
	40% {
		clip: rect(103px, 920px, 53px, 0);
	}
	45% {
		clip: rect(92px, 920px, 6px, 0);
	}
	50% {
		clip: rect(69px, 920px, 46px, 0);
	}
	55% {
		clip: rect(8px, 920px, 87px, 0);
	}
	60% {
		clip: rect(12px, 920px, 15px, 0);
	}
	70% {
		clip: rect(28px, 920px, 106px, 0);
	}
	80% {
		clip: rect(98px, 920px, 63px, 0);
	}
	85% {
		clip: rect(11px, 920px, 44px, 0);
	}
	95% {
		clip: rect(23px, 920px, 84px, 0);
	}
	100% {
		clip: rect(66px, 920px, 91px, 0);
	}
}

@keyframes glitch-2 {
	0% {
		clip: rect(86px, 920px, 25px, 0);
	}
	5% {
		clip: rect(30px, 920px, 17px, 0);
	}
	10% {
		clip: rect(54px, 920px, 43px, 0);
	}
	15% {
		clip: rect(10px, 920px, 86px, 0);
	}
	25% {
		clip: rect(62px, 920px, 1px, 0);
	}
	30% {
		clip: rect(61px, 920px, 24px, 0);
	}
	35% {
		clip: rect(38px, 920px, 46px, 0);
	}
	40% {
		clip: rect(115px, 920px, 8px, 0);
	}
	45% {
		clip: rect(87px, 920px, 44px, 0);
	}
	50% {
		clip: rect(6px, 920px, 65px, 0);
	}
	55% {
		clip: rect(83px, 920px, 99px, 0);
	}
	65% {
		clip: rect(25px, 920px, 71px, 0);
	}
	70% {
		clip: rect(37px, 920px, 55px, 0);
	}
	75% {
		clip: rect(115px, 920px, 18px, 0);
	}
	80% {
		clip: rect(68px, 920px, 46px, 0);
	}
	90% {
		clip: rect(47px, 920px, 31px, 0);
	}
	95% {
		clip: rect(47px, 920px, 84px, 0);
	}
	100% {
		clip: rect(93px, 920px, 53px, 0);
	}
}

/* Mouse Button Animations */
@keyframes mouse-anim {
	0% {
		top: 0px;
	}
	50% {
		top: 10px;
	}
	100% {
		top: 0px;
	}
}


/* Sidebar */

.s_overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	z-index: 106;
	opacity: 0.6;
	display: none;
}

.sidebar_btn {
	z-index: 100;
	position: relative;
	float: left;
	width: 42px;
	height: 42px;
	line-height: 40px;
	text-align: center;
	color: $default-color;
	font-size: $default-size;
	background: none;
	//border: 1px solid #dddddd;
	overflow: hidden;
	padding: 0 30px;
	cursor: pointer;
	transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;

	@media (max-width: 840px) {
		display: none;
	}

}

.sidebar_btn span {
	margin: 0 0 0 -15px;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 30px;
	height: 1px;
	background: $default-color;
	transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
}

.sidebar_btn span:before, .sidebar_btn span:after {
	content: '';
	position: absolute;
	left: 0;
	top: -8px;
	width: 100%;
	height: 1px;
	background: $default-color;
	transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
}

.sidebar_btn span:after {
	top: auto;
	bottom: -8px;
}

.sidebar_btn:hover {
	//color: $white-color;
	//background: $active-color;
	//border: 1px solid $active-color;

	span {
		background: $active-color;

		&:before, &:after {
			background: $active-color;
		}
	}
}

.content-sidebar {
	position: fixed;
	top: 0;
	left: -368px;
	width: 300px;
	height: 100%;
	background: $bg;
	z-index: 107;
	transition: all 0.6s ease 0s;
	-moz-transition: all 0.6s ease 0s;
	-webkit-transition: all 0.6s ease 0s;
	-o-transition: all 0.6s ease 0s;
}

.content-sidebar.active {
	left: 0;
}

.content-sidebar .widget-area {
	position: relative;
	overflow: auto;
	height: 100vh;
}

.content-sidebar .widget {
	padding: 25px;
}

.content-sidebar .widget tfoot {
	display: none;
}

.content-sidebar .widget th {
	color: #000;
	padding: 5px;
	text-align: center;
}

.content-sidebar .widget td {
	padding: 5px;
	text-align: center;
}

.content-sidebar .widget label {
	padding-bottom: 0;
}

.content-sidebar .widget ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.content-sidebar .widget ul ul {
	margin-top: 10px;
	margin-left: 0;
	margin-bottom: 10px;
	padding: 15px 10px;
	background: #f6f6f6;
	font-size: $small-size;
}

.content-sidebar .widget ul ul ul {
	margin-top: 10px;
	margin-left: 5px;
	margin-bottom: 0;
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
}

.content-sidebar .widget ul li {
	padding-bottom: 10px;
	margin-bottom: 10px;
	color: $extra-color;
	border-bottom: 1px solid #f2f2f2;
}

.content-sidebar .widget ul li li {
	padding-bottom: 0;
	border-bottom: none;
}

.content-sidebar .widget ul li:last-child {
	margin-bottom: 0;
}

.content-sidebar .widget ul li a {
	padding-bottom: 5px;
}

.content-sidebar .widget ul li a:hover {
	color: $active-color;
}

.content-sidebar #results-container {
	padding-top: 10px;
}

.content-sidebar .widget.widget_recent_comments a, .content-sidebar .widget.widget_recent_entries a, .content-sidebar .widget.widget_rss a {
	padding-top: 5px;
	font-weight: 400;
	display: block!important;
}

.content-sidebar .widget ul li .post-date {
	margin: 0;
	display: inline-block;
	padding: 0;
	height: 20px;
	line-height: 18px;
	font-weight: 400;
	font-size: $extra-small-size;
	color: $extra-color;
}

.content-sidebar .widget img {
	max-width: 100%;
	height: auto;
}

.content-sidebar .widget .rss-date, .content-sidebar .widget cite {
	margin: 5px 0 10px 0;
	padding: 0;
	display: block;
	height: 20px;
	line-height: 18px;
	font-weight: 400;
	font-size: $extra-small-size;
	color: $extra-color;
}

.content-sidebar .widget cite {
	margin: 10px 0 0 0;
}

.content-sidebar .widget .rssSummary {
	color: $default-color;
}

.content-sidebar span.screen-reader-text {
	display: none;
}

.content-sidebar span.screen-reader-text span {
	box-shadow: inset 0 -6px 0px $extra-active-color;
	-moz-box-shadow: inset 0 -6px 0px $extra-active-color;
	-webkit-box-shadow: inset 0 -6px 0px $extra-active-color;
	-khtml-box-shadow: inset 0 -6px 0px $extra-active-color;
}

.search-form input.search-field {
	border: solid 1px #ddd;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0 50px 0 15px;
	box-shadow: none;
	font-weight: 400;
}

.search-form input.search-submit {
	margin-top: -34px;
	margin-right: 12px;
	float: right;
	width: 18px;
	height: 18px;
	background: url(../images/android-search.png) no-repeat center center;
	background-size: 18px 18px;
	font-size: 0;
	border: none;
}

.content-sidebar h2.widget-title {
	padding-bottom: 20px;
	margin: -26px -25px 25px -25px;
	border-bottom: 1px solid #dddddd;
	padding: 25px 30px;
	display: block;
	line-height: 18px;
	font-size: $default-size;
	color: $dark-color;
	text-transform: uppercase;
	letter-spacing: 0.04em;
	font-weight: 400;
}

.content-sidebar h2.widget-title span {
	position: relative;
	left: -5px;
	box-shadow: inset 0 -6px 0px $extra-active-color;
	-moz-box-shadow: inset 0 -6px 0px $extra-active-color;
	-webkit-box-shadow: inset 0 -6px 0px $extra-active-color;
	-khtml-box-shadow: inset 0 -6px 0px $extra-active-color;
}

.content-sidebar .close {
	position: absolute;
	top: 0;
	left: auto;
	right: -68px;
	width: 68px;
	height: 68px;
	background: #f9f9f9;
	cursor: pointer;
}

.content-sidebar .close:before, .content-sidebar .close:after {
	content: '';
	margin: 0 0 0 -10px;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 20px;
	height: 1px;
	background: $default-color;
	transform: rotate(45deg);
}

.content-sidebar .close:after {
	transform: rotate(-45deg);
}

.content-sidebar .recentcomments {
	font-size: $extra-small-size;
	color: $extra-color;
}

.content-sidebar .recentcomments a {
	padding-top: 6px;
	display: block!important;
	font-size: $default-size;
}

.content-sidebar .recentcomments a.url {
	margin: 0;
	padding: 0;
	display: inline-block;
	height: 20px;
	line-height: 18px;
	font-weight: 400;
	font-size: $extra-small-size;
	color: $extra-color;
}

.content-sidebar .recentcomments .comment-author-link {
	margin: 0;
	padding: 0;
	display: inline-block;
	height: 20px;
	line-height: 18px;
	font-weight: 400;
	font-size: $extra-small-size;
	color: $extra-color;
}

.content-sidebar .tagcloud a {
	margin: 4px 2px;
	display: inline-block;
	vertical-align: bottom;
	padding: 0 5px;
	font-weight: 500;
	font-size: $extra-small-size;
	color: $active-color;
	border: 1px solid $active-color;
}

.content-sidebar .screen-reader-text {
	display: none;
}

.content-sidebar .widget select {
	width: 100%;
	height: 34px;
}

.content-sidebar table {
	margin: 0;
}

.content-sidebar .widget.widget_media_image {
	max-width: 100%;
}

@media (max-width: 840px) {
	.content-sidebar {
		width: 260px;
	}
	.sidebar_btn {
		margin-left: auto;
		position: relative;
		top: 0;
		right: 0;
		margin-top: 20px;
	}
	.sidebar_btn, .sidebar_btn span, .sidebar_btn span:before, .sidebar_btn span:after {
		transition: all 0s ease 0s;
		-moz-transition: all 0s ease 0s;
		-webkit-transition: all 0s ease 0s;
		-o-transition: all 0s ease 0s;
	}
}

::selection {
    background: $selection-color;
    color: #ffffff;
}

ion-icon {
	pointer-events: none;
}